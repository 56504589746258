const { env } = require("./env");

const config = {
  local: {
    firebaseApiKey: "AIzaSyDpnXKLck64A5Q8Fv856rIO5k_1AEP8opY",
    firebaseBaseUrl: "televet-v2.firebaseapp.com",
    vetWebApp: "http://localhost:3000",
    petWeb: "https://pets-qa1.televet.com", // TODO: Make it possible to switch this out with other QAs in the app
    // beta: "https://beta-qa9.televet.com",
    beta: "http://localhost:3000",
    care: "https://care-qa1.televet.com",
    title: "local",
  },
  demo: {
    firebaseApiKey: "AIzaSyDpnXKLck64A5Q8Fv856rIO5k_1AEP8opY",
    firebaseBaseUrl: "televet-v2.firebaseapp.com",
    vetWebApp: "https://demo.otto.vet",
    petWeb: "https://pet-demo.otto.vet",
    beta: "https://demo.otto.vet",
    care: "https://care-demo.televet.com",
    title: "demo",
  },
  development: {
    firebaseApiKey: "AIzaSyDpnXKLck64A5Q8Fv856rIO5k_1AEP8opY",
    firebaseBaseUrl: "televet-v2.firebaseapp.com",
    vetWebApp: "https://flow.test.dev.otto.vet",
    petWeb: "https://pets.test.dev.otto.vet",
    beta: "https://flow.test.dev.otto.vet",
    care: "https://care-qa1.televet.com",
    title: "development",
  },
  production: {
    firebaseApiKey: "AIzaSyC49HZMqEv_ucQ2GxP2rpUBxK5jOji_x7Y",
    firebaseBaseUrl: "televet-83069.firebaseapp.com",
    vetWebApp: "https://flow.otto.vet",
    petWeb: "https://pet.otto.vet",
    beta: "https://flow.otto.vet",
    care: "https://care.televet.com",
    title: "production",
  },
  owl: {
    firebaseApiKey: "AIzaSyC49HZMqEv_ucQ2GxP2rpUBxK5jOji_x7Y",
    firebaseBaseUrl: "televet-83069.firebaseapp.com",
    vetWebApp: "https://flow.owl.otto.vet",
    petWeb: "https://pet.owl.otto.vet",
    beta: "https://flow.owl.otto.vet",
    care: "https://care.owl.televet.com",
    title: "production",
  },
  yak: {
    firebaseApiKey: "AIzaSyC49HZMqEv_ucQ2GxP2rpUBxK5jOji_x7Y",
    firebaseBaseUrl: "televet-83069.firebaseapp.com",
    vetWebApp: "https://flow.yak.televet.com",
    petWeb: "https://pets.televet.com",
    beta: "https://flow.yak.televet.com",
    care: "https://care.yak.televet.com",
    title: "production",
  },
};
let tempConfig = config[process.env.REACT_APP_ENV] || config.development;

if (env.REACT_APP_PET_WEB_URL) {
  tempConfig.care = env.REACT_APP_PET_WEB_URL;
  tempConfig.beta = env.REACT_APP_PET_WEB_URL.replace("pets", "flow")
    .replace("pet", "flow")
    .replace("care", "flow");
}

module.exports = tempConfig;
